import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {
  BusinessPropertiesService,
  BusinessUnitIdDto,
  DictionaryBaseService,
  DictionaryDto,
  LoggedUserService,
  PolicyContractService,
  PolicyContractVersionDto,
  RouterService,
  UserIdDto,
  UserRange,
  UserService,
} from '../../../bonding_shared';
import {AbstractInquiryProduct} from './abstract-inquiry.product';
import {VisibilityService} from '../services/visibility.service';

@Component({
  selector: 'inquiry-product-mehib',
  templateUrl: './inquiry-product-mehib.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class InquiryProductMehibComponent extends AbstractInquiryProduct implements OnInit {
  @Input() myOptions: DictionaryDto[];

  readonly representativeOfficeUserRange: UserRange = 'representativeOffice';

  underwriters: UserIdDto[] = null;

  constructor(
    dictionaryBaseService: DictionaryBaseService,
    router: RouterService,
    businessPropertiesService: BusinessPropertiesService,
    private userService: UserService,
    protected policyContractService: PolicyContractService,
    protected loggedUserService: LoggedUserService,
    protected visibilityService: VisibilityService
  ) {
    super(
      dictionaryBaseService,
      router,
      loggedUserService,
      policyContractService,
      businessPropertiesService,
      visibilityService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.loadUnderwriters();

    this.formUpdate();
  }

  protected loadSalesUsers() {
    const businessUnit: BusinessUnitIdDto = this.inquiry.businessUnit;
    this.userService.getSalesUsers(businessUnit).subscribe((users) => {
      this.salesUsers = users;
    });
  }

  protected loadUnderwriters() {
    if (!this.readonly) {
      this.userService.getPolicySupport().subscribe((res) => {
        this.underwriters = res;
      });
    }
  }

  onSelectPolicy(c: PolicyContractVersionDto) {
    this.inquiry.policyInquiry.policyContract = c.policyContract;
  }
}
