import {Directive, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  ATableComponent,
  BaseInsurersLimitDto,
  BusinessObjectDto,
  BusinessPropertiesService,
  CompanySimpleDto,
  ContactPersonCriteriaDto,
  ContactPersonDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  DictionaryDto,
  IMultiSelectSettings,
  LoggedUserService,
  OutstandingsBuyerDto,
  PolicyContractService,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionDto,
  PolicyInquiryBaseInsurerCompanyDto,
  PolicyInquiryCoInsurerCompanyDto,
  PolicyInquiryCompanyBaseDto,
  PolicyInquiryCompanyDto,
  PolicyInquiryPolicyDataDto,
  PolicyInquiryProductConfigurationDto,
  PolicyInquiryVersionDto,
  PolicyInquiryVersionSimpleDto,
  RouterService,
  UserIdDto,
} from '../../../bonding_shared';
import {Subject} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
  BusinessObjectType,
  CompanyType,
  DictionaryProfile,
  InquiryProductType,
  PolicyInquiryCompanyRole,
} from '../../../bonding_shared/model/dictionary-ids';
import {
  IPasteFromExcelColumnConfig,
  IPasteFromExcelConfig,
} from '../../../bonding_shared/components/aku-table/services/paste-from-excel.service';
import {CompanySelectedEvent} from '../../company/components/shared/extended-selectors/company-selector-extended.component';
import {ContactPersonDisplayNamePipe} from '../../../bonding_shared/pipes/contact-person-display-name.pipe';
import {VisibilityService} from '../services/visibility.service';

@Directive()
export abstract class AbstractInquiryProduct implements OnInit {
  protected _inquiry: PolicyInquiryVersionDto;
  protected _contactPersonCriteria: ContactPersonCriteriaDto;
  businessObject = <BusinessObjectDto>{};

  readonly InquiryProductType = InquiryProductType;
  readonly CompanyType = CompanyType;
  readonly DictionaryProfile = DictionaryProfile;
  public policySearchCriteria = <PolicyContractVersionCriteriaDto>{};
  public activeOrLastNotErrorPolicyVersion: PolicyContractVersionDto = null;

  visible: any = {};

  get inquiry() {
    return this._inquiry;
  }

  @Input()
  set inquiry(inq: PolicyInquiryVersionDto) {
    this._inquiry = inq;
    this.loadSalesUsers();
    this.loadUnderwriters();
    this.initPolicySearchCriteria();
    this.formUpdate();
    this.visible = this.visibilityService.getVisibility(this.inquiry.productType.id);
  }

  get contactPersonCriteria() {
    return this._contactPersonCriteria;
  }

  @Input()
  set contactPersonCriteria(criteria: ContactPersonCriteriaDto) {
    this._contactPersonCriteria = criteria;
    this.businessObject.relatedToId = criteria.companyId;
    this.businessObject.relatedTo = <DictionaryBaseDto>{id: BusinessObjectType.COMPANY};
  }

  @Input() policy: PolicyInquiryPolicyDataDto;
  @Input() openSelectorEmitters: {[key: string]: Subject<boolean>} = {};
  _form: UntypedFormGroup;
  get form() {
    return this._form;
  }
  @Input()
  set form(f: UntypedFormGroup) {
    this._form = f;

    const addControlIfNo = (name: string) => {
      if (f.get(name) === null) {
        f.addControl(name, new UntypedFormControl(''));
      } else {
        console.warn('Control existed', name, f);
      }
    };
    addControlIfNo('domesticInsurable');
    addControlIfNo('exportInsurable');
    addControlIfNo('turnoverInsurable');

    this.formUpdate();
  }

  @Input() productConfig = <PolicyInquiryProductConfigurationDto>{};
  @Input() showErrors = false;
  @Input() subinsuredEmpty = false;
  @Input() lostReceivablesEmpty = false;
  @Input() countryGroupsOptions: DictionaryDto[];
  @Input() readonly = false;
  @Output() turnoverChange = new EventEmitter<void>();
  @ViewChild('domesticBuyerRange', {static: true}) domesticBuyerRange: ATableComponent<OutstandingsBuyerDto>;

  salesUsers: UserIdDto[] = null;

  maxYear: number = new Date().getFullYear();
  pasteBuyersFromExcelConfigs: IPasteFromExcelConfig[] = [
    {
      columnsCount: 3,
      columns: [
        <IPasteFromExcelColumnConfig>{
          type: 'multi',
          subColumns: [
            <IPasteFromExcelColumnConfig>{
              type: 'number',
              property: 'rangeFrom',
              regExp: /^[\s]*([0-9]+)[\s]*[-–]/,
            },
            <IPasteFromExcelColumnConfig>{
              type: 'number',
              property: 'rangeTo',
              regExp: /[-–][\s]*([0-9]+)[\s]*$/,
            },
          ],
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'numberOfBuyers',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'outstandingsValue',
        },
      ],
    },
  ];

  pasteFLFromExcelConfig: IPasteFromExcelConfig[] = [
    {
      columns: [
        <IPasteFromExcelColumnConfig>{
          type: 'text',
          property: 'customerName',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'dictionary',
          property: 'currency',
          dictionary: 'Currency',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'requestedLimit',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'grantedLimit',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'limitDifference',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'topUpLimit',
        },
      ],
    },
  ];

  pasteCountriesFromExcelConfigs: IPasteFromExcelConfig[] = [
    {
      columns: [
        <IPasteFromExcelColumnConfig>{
          type: 'dictionary',
          property: 'country',
          dictionary: 'Country',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'numberOfBuyers',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'lastYearsTO',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'plannedTO',
        },
      ],
    },
  ];

  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    selectionLimit: 1000,
    numSelectedOff: true,
    showCheckAll: true,
    showUncheckAll: true,
  };

  portalMode = false;

  protected constructor(
    protected dictionaryBaseService: DictionaryBaseService,
    public router: RouterService,
    protected loggedUserService: LoggedUserService,
    protected policyContractService: PolicyContractService,
    protected businessPropertiesService: BusinessPropertiesService,
    protected visibilityService: VisibilityService
  ) {
    this.portalMode = this.loggedUserService.portal;
  }

  ngOnInit(): void {
    this.form.addControl('broker', new UntypedFormControl(''));
  }

  onSelectCurrentInsurer(company: CompanySimpleDto) {
    this.inquiry.currentInsurer = company;
  }

  onSelectBroker(company: CompanySimpleDto) {
    this.inquiry.broker = company;
    this.form.controls.broker.setValue(this.inquiry.broker);
  }

  getContactPersonFullName(cp: ContactPersonDto) {
    return new ContactPersonDisplayNamePipe().transform(cp);
  }

  addBuyerRange() {
    const buyerRange = <OutstandingsBuyerDto>{};
    const itemNo = this.inquiry.outstandingsBuyers.length;
    if (itemNo > 0) {
      buyerRange.rangeFrom = this.inquiry.outstandingsBuyers[itemNo - 1].rangeTo + 0.01;
    }
    this.domesticBuyerRange.onAddItem(buyerRange);
  }

  addSubinsuredExtended(e: CompanySelectedEvent) {
    const subinsured = <PolicyInquiryCompanyDto>{company: e.company, thirdParty: e.thirdParty};
    this.setCompanyRole(subinsured, PolicyInquiryCompanyRole.SUBINSURED);
    this.inquiry.subinsureds.push(subinsured);
    this.inquiry.subinsureds = [].concat(this.inquiry.subinsureds);
  }

  onSelectContactPerson(person: ContactPersonDto) {
    this.inquiry.contactPerson = person;
  }

  addInsurer(company: CompanySimpleDto) {
    const insurer = <PolicyInquiryBaseInsurerCompanyDto>{company: company};
    this.setCompanyRole(insurer, PolicyInquiryCompanyRole.BASE_INSURER);
    this.inquiry.baseInsurers.push(insurer);
  }

  addCoInsurer(company: CompanySimpleDto) {
    const insurer = <PolicyInquiryCoInsurerCompanyDto>{company: company};
    this.setCompanyRole(insurer, PolicyInquiryCompanyRole.CO_INSURER);
    this.inquiry.coInsurers.push(insurer);
  }

  setCompanyRole(company: PolicyInquiryCompanyBaseDto, roleId: number) {
    this.dictionaryBaseService.getDictionaryEntry('PolicyInquiryCompanyRole', roleId).subscribe((entry) => {
      company.role = entry;
    });
  }

  recalculateFLLimits(baseInsurersLimit: BaseInsurersLimitDto) {
    baseInsurersLimit.limitDifference = baseInsurersLimit.requestedLimit - baseInsurersLimit.grantedLimit;
    if (baseInsurersLimit.limitDifference < baseInsurersLimit.grantedLimit) {
      baseInsurersLimit.topUpLimit = Math.max(0, baseInsurersLimit.limitDifference);
    } else {
      baseInsurersLimit.topUpLimit = baseInsurersLimit.grantedLimit;
    }
  }

  onAddBaseInsurersLimit(baseInsurersLimit: BaseInsurersLimitDto) {
    baseInsurersLimit.currency = this.inquiry.currency;
  }

  showOffer() {
    return this.inquiry && this.inquiry.id && !this.portalMode;
  }

  isTopUp(v: PolicyInquiryVersionSimpleDto): boolean {
    return v.productType.id === InquiryProductType.TOP_UP;
  }

  unclickCheckbox(
    fieldName: 'subinsuredsNoInfo' | 'subinsuredsNotExist' | 'lostReceivablesNoInfo' | 'lostReceivablesNotExist',
    unclick: boolean
  ) {
    if (unclick) {
      this.inquiry[fieldName] = false;
    }
  }

  protected loadUnderwriters() {}
  protected abstract loadSalesUsers();

  protected initPolicySearchCriteria() {
    this.policySearchCriteria.linkedCompanyId = null;
    if (
      this.inquiry &&
      this.inquiry.client &&
      this.inquiry.client.company &&
      this.inquiry.client.company.id &&
      this.inquiry.client.company.id > 0
    ) {
      this.policySearchCriteria.linkedCompanyId = this.inquiry.client.company.id;
    }
    this.initActiveOrLastNotErrorPolicyVersion();
  }

  private initActiveOrLastNotErrorPolicyVersion() {
    this.activeOrLastNotErrorPolicyVersion = null;
    if (
      this.inquiry &&
      this.inquiry.policyInquiry &&
      this.inquiry.policyInquiry.policyContract &&
      this.inquiry.policyInquiry.policyContract.id &&
      this.inquiry.policyInquiry.policyContract.id > 0
    ) {
      this.policyContractService
        .getActiveOrLastNotErrorPolicyContractVersion(this.inquiry.policyInquiry.policyContract.id)
        .subscribe(
          (pcv) => (this.activeOrLastNotErrorPolicyVersion = pcv),
          (error) => console.error('Loading activeOrLastNotErrorPolicyVersion error ', error)
        );
    }
  }

  public beforeSave(inquiry: PolicyInquiryVersionDto) {}

  protected formUpdate() {}

  get showLimits() {
    return (
      this.inquiry &&
      this.inquiry.id &&
      this.businessPropertiesService.properties &&
      this.businessPropertiesService.properties.policyInquiryProductsForLimit.includes(this.inquiry.productType.id)
    );
  }

  addSubinsured(company: CompanySimpleDto) {
    const subinsured = <PolicyInquiryCompanyDto>{company: company};
    this.setCompanyRole(subinsured, PolicyInquiryCompanyRole.SUBINSURED);
    this.inquiry.subinsureds.push(subinsured);
  }
}
