import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ControlContainer, NgForm, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {
  ATableComponent,
  CompanySimpleDto,
  DictionaryBaseDto,
  DictionaryService,
  GrowlService,
  InquiryService,
  LoggedUserService,
  PolicyContractBaseDto,
  PolicyInquiryCountryDto,
  PolicyInquiryOfferCompanyDto,
  PolicyInquiryOfferEnvelopeDto,
  PolicyInquiryOfferRejectionReasonDto,
  PolicyInquiryVersionDto,
  PolicyInquiryVersionSimpleDto,
  RouterService,
  StringUtils,
  UserIdDto,
  UserService,
} from '../../../bonding_shared';
import {AsyncSubject, Observable, Subject} from 'rxjs';
import {
  DictionaryProfile,
  FieldValueType,
  GlobalConditionsOfInsurance,
  InquiryProductType,
  InquiryProductTypes,
  PolicyContractVersionOrigin,
  PolicyInquiryOfferStatus,
} from '../../../bonding_shared/model/dictionary-ids';
import {NaceCodeService} from '../../../bonding_shared/services/naceCode.service';
import {VisibilityService} from '../services/visibility.service';
import * as moment from 'moment';
import {validateFixedMinPremiums} from '../../policy/domain/fixed-min-premiums';
import {InquiryCountries} from '../domain/inquiry-countries';
import {distinct, skip, take} from 'rxjs/operators';
import {
  areClausesAvailable,
  ClientOfferAllowance,
  isAnnexOffer,
  isClauseExists,
  isInitialOffer,
  isNarrowEditable,
  isNotType,
  isProductPacked,
  isRenewalOffer,
  isShortOffer,
  isStandardEditable,
  isType,
} from '../domain/offer-allowance';
import {getOfferVisibility} from '../domain/type-features';
import {getInquiryVersion, OfferCommon} from '../domain/offer-common';
import {PolicyContractViewService} from '../../policy/services/policy-contract-view.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'kuke-offer-form',
  templateUrl: 'kuke-offer-form.component.pug',
  styles: [
    '.vote-users {display: inline-block; padding-left: 0; width: calc(100% - 220px);}',
    '.vote-users li {display: inline-block; padding-right: 25px;}',
    '.user-comment {font-style: italic; display: inline-block; margin-bottom: 1.2em;}',
    '.user-name {font-size: 1.2em; display:block}',
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}, PolicyContractViewService],
})
export class KukeOfferFormComponent implements OnInit {
  self = this;

  @ViewChild('subinsuredsExclusions')
  set specialRisksList(cmp: ATableComponent<PolicyInquiryOfferCompanyDto>) {
    if (cmp) {
      this._specialRisksList = cmp;
    }
  }

  get specialRisksList() {
    return this._specialRisksList;
  }

  _specialRisksList: ATableComponent<PolicyInquiryOfferCompanyDto>;
  private _offerEnvelope: PolicyInquiryOfferEnvelopeDto;
  private globalConditionsOfInsuranceId: number;

  readonly OfferCommon = OfferCommon;

  @Input()
  set offerEnvelope(envelope: PolicyInquiryOfferEnvelopeDto) {
    this._offerEnvelope = envelope;

    this.initGlobalLOLTypeNN();
    if (this.getTypeId()) {
      this.initVisibility();
      this.visibleInquiry = this.visibilityService.getVisibility(this.getTypeId());

      this.inquiryIdSubject.subscribe((inquiryId) =>
        setTimeout((_) => {
          this.inquiryService
            .getById<PolicyInquiryVersionDto>(inquiryId)
            .subscribe((inq) => this.setInquiryVersion(inq));
        }, 500)
      );

      this.loadUsers();

      if (!envelope.offer.id && !this.isAnnexOffer) {
        this.recalculateContractDuration(this.isEdge());
        this.recalculateValidToDate(this.isEdge());
      }
    }

    const oe = this.offerEnvelope;
    this.globalConditionsOfInsuranceId =
      oe && oe.offer && oe.offer.globalConditionsOfInsurance && oe.offer.globalConditionsOfInsurance.id;
    // this.nnLimitClauseUpdate();
  }

  get offerEnvelope() {
    return this._offerEnvelope;
  }

  @Input()
  form: UntypedFormGroup;
  @Input()
  set inquiryId(id: number) {
    // inquiry version id
    const is = this.inquiryIdSubject;
    if (id > 0 && !is.closed) {
      is.next(id);
      is.complete();
    }
  }
  @Input()
  showErrors: boolean;
  @Input()
  specialRisksEmitter: Subject<boolean>;
  private _canSave = false;

  @Input()
  set canSave(cs) {
    this._canSave = cs;
    if (this._canSave) {
      this.refreshDeclaredTurnoverObserver.canEditEmit();
    }
  }
  get canSave() {
    return this._canSave;
  }
  @Input()
  isRequired: boolean;

  @Output() offerRefresh = new EventEmitter<() => Observable<PolicyInquiryOfferEnvelopeDto>>();

  globalLOLTypeNN: DictionaryBaseDto;

  visible: {[key: string]: boolean} = {};
  visibleInquiry: {[key: string]: boolean} = {};
  disabled: {[key: string]: boolean} = {};

  rejectionReason: PolicyInquiryOfferRejectionReasonDto = <PolicyInquiryOfferRejectionReasonDto>{};

  inquiryVersion: PolicyInquiryVersionDto = null;

  readonly policyValidFromMax: Date = moment().add(30, 'days').toDate();
  readonly policyValidFromMin: Date = moment().subtract(30, 'days').toDate();
  readonly today: Date = moment().toDate();

  readonly PolicyInquiryOfferStatus = PolicyInquiryOfferStatus;

  bokUsers: UserIdDto[] | null | false = null;
  nnLimitClause = null;

  private refreshDeclaredTurnoverObserver = {
    canEditEmit: () => void 0,
    inquiryLoadEmit: () => void 0,
  };

  // inquiry version id
  private readonly inquiryIdSubject = new AsyncSubject<number>();
  readonly dictionaryProfile = DictionaryProfile;

  constructor(
    private route: ActivatedRoute,
    public routerService: RouterService,
    public inquiryService: InquiryService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected loggedUserService: LoggedUserService,
    protected userService: UserService,
    protected dictionaryService: DictionaryService,
    protected naceCodeService: NaceCodeService,
    private visibilityService: VisibilityService
  ) {
    this.initRefreshDeclaredTurnoverObserver();
  }

  private initRefreshDeclaredTurnoverObserver() {
    const emptyObserver = this.refreshDeclaredTurnoverObserver;
    new Observable((observer) => {
      this.refreshDeclaredTurnoverObserver = {
        canEditEmit: () => observer.next('canEditEmit'),
        inquiryLoadEmit: () => observer.next('inquiryLoadEmit'),
      };
    })
      .pipe(distinct(), skip(1), take(1))
      .subscribe({
        next: (_) => this.refreshDeclaredTurnoverFromCountries(),
        error: () => void 0,
        complete: () => {
          this.refreshDeclaredTurnoverObserver = emptyObserver;
        },
      });
  }

  declaredTurnoverComputedFromContries() {
    return this.isProductPacked() && !this.isAnnexOrRenewalOffer;
  }

  ngOnInit() {}

  isAddOfferButton() {
    const ofe = this.offerEnvelope && this.offerEnvelope.offer;
    return (
      ofe &&
      ofe.edge &&
      ofe.businessStatus &&
      (ofe.businessStatus.id === PolicyInquiryOfferStatus.ACTIVE ||
        ofe.businessStatus.id === PolicyInquiryOfferStatus.CHANGED_ACCEPTED_BY_INSURER) &&
      ClientOfferAllowance.isClientOfferAllowed(ofe && ofe.type)
    );
  }

  deleteClause(type: DictionaryBaseDto) {
    console.log('remove condition', type);
    // this.form.removeControl(CustomFormComponent.getClauseGroupName(type.id));
  }

  globalLOLAmount(): boolean {
    return (
      this.offerEnvelope.offer.globalLOLType && this.offerEnvelope.offer.globalLOLType.id === FieldValueType.AMOUNT
    );
  }

  globalLOLAmountNN(): boolean {
    return this.globalLOLTypeNN && this.globalLOLTypeNN.id === FieldValueType.AMOUNT;
  }

  globalLOLTypeChanged() {
    if (this.globalLOLAmount()) {
      this.offerEnvelope.offer.globalLOLMultiplicity = undefined;
    } else {
      this.offerEnvelope.offer.globalLOL = undefined;
      this.offerEnvelope.offer.globalLOLCurrency = undefined;
    }
  }

  nnRateChanged() {
    if (!this.offerEnvelope.offer.nnRate) {
      this.offerEnvelope.offer.nnRateValue = undefined;
    }
  }

  createdBy(): string {
    return StringUtils.userName(this.offerEnvelope.offer.createdBy);
  }

  payableInInstallmentsChanged() {
    if (!this.offerEnvelope.offer.payableInInstallments) {
      this.offerEnvelope.offer.numberOfInstalments = undefined;
    }
  }

  onSelectedNaceCode(naceCode: DictionaryBaseDto) {
    this.naceCodeService.getIndustry(naceCode.id).subscribe((i) => {
      this.offerEnvelope.offer.tradeName = i.name;
    });
  }

  changeGlobalLOLTypeNN(p: any) {
    this.resetNN();
  }

  private initGlobalLOLTypeNN() {
    const offer = this.offerEnvelope.offer;
    let typeId;

    const isNumber = (v) => typeof v === 'number' && isFinite(v);

    if (isNumber(offer.globalLOLMultiplicityNN)) {
      typeId = FieldValueType.MULTIPLICITY;
    } else if (isNumber(offer.globalLOLNN)) {
      typeId = FieldValueType.AMOUNT;
    } else {
      typeId = FieldValueType.MULTIPLICITY;
    }

    if (typeId !== null) {
      this.dictionaryService.getDictionaryEntry('FieldValueType', typeId).subscribe((v) => {
        this.globalLOLTypeNN = v;
        this.resetNN();
      });
    } else {
      this.globalLOLTypeNN = null;
      this.resetNN();
    }
  }

  private resetNN() {
    const offer = this.offerEnvelope.offer;
    const isAmountNN = this.globalLOLAmountNN();
    if (isAmountNN !== false) {
      offer.globalLOLMultiplicityNN = null;
    }
    if (isAmountNN !== true) {
      offer.globalLOLNN = null;
    }
  }

  private initVisibility() {
    this.visible = {};
    this.disabled = {};
    if (
      !this.offerEnvelope ||
      !this.offerEnvelope.offer ||
      !this.offerEnvelope.offer.type ||
      !this.offerEnvelope.offer.type.id
    ) {
      return;
    }

    this.setVisible();
  }

  setVisible() {
    const typeId = this.getTypeId();
    this.visible = getOfferVisibility.bind(this)(typeId, this.offerEnvelope.offer.globalConditionsOfInsurance.id);
  }

  isKukeKom(): boolean {
    return this.isProductPacked() || this.isType(InquiryProductType.KUKE_KOM_EP);
  }

  private getTypeId() {
    const type = this.getType();
    return type && type.id;
  }

  isKukeKomSP() {
    return this.isType(InquiryProductType.KUKE_KOM_SP);
  }

  isType(typeId: InquiryProductType) {
    return isType(this.getType(), typeId);
  }

  isNotType(typeId: InquiryProductType) {
    return isNotType(this.getType(), typeId);
  }

  private getType() {
    const offer = this.offerEnvelope.offer;
    return offer && offer.type;
  }

  addSpecialRisk(company: CompanySimpleDto) {
    this.specialRisksList.onAddItem(this.risksFactory(company));
  }

  private risksFactory: any = (company: CompanySimpleDto) => {
    return <PolicyInquiryOfferCompanyDto>{company: company, amount: 0};
  };

  // check edge offer
  isParentOfferEmpty() {
    const enve = this.offerEnvelope;
    return !(enve && enve.offer && enve.offer.parent);
  }

  getInquiry(): PolicyInquiryVersionSimpleDto {
    const offer = this.offerEnvelope && this.offerEnvelope.offer;
    return getInquiryVersion(offer);
  }

  private getPolicyContract(): PolicyContractBaseDto | null {
    return this.getInquiry().policyInquiry.policyContract;
  }

  getInquiryNumber() {
    const inq = this.getInquiry();
    return inq && inq.policyInquiry.number;
  }

  insuranceCoverDisabled() {
    return (
      this.isType(InquiryProductType.KUKE_KOM_EP) ||
      this.isFetchFromCalculator() ||
      this.isType(InquiryProductType.KUKE_GSP_PBG)
    );
  }

  globalLOLMultiplicityDisabled() {
    return this.isFetchFromCalculator();
  }

  owuDisabled() {
    const gcId = this.globalConditionsOfInsuranceId;
    return !(gcId === GlobalConditionsOfInsurance.KUKE_OP_2016_01) && !!gcId;
  }

  premiumDisabled() {
    return this.isProductPacked() && this.isEdge();
  }

  fixedPremiumDisabled() {
    return this.isType(InquiryProductType.KUKE_KOM_EP);
  }

  noticePeriodVisible() {
    return this.isNotType(InquiryProductType.KUKE_KOM_EP);
  }

  standardPaymentTermVisible(section: 'deadlinesSection' | 'detailsSection') {
    if (this.isProductPacked()) {
      return section === 'deadlinesSection';
    }
    if (this.isType(InquiryProductType.KUKE_KOM_EP) || this.isType(InquiryProductType.KUKE_GSP_PBG)) {
      return section === 'detailsSection';
    }

    return false;
  }

  policyLimitFeesSectionVisible() {
    return this.isNotType(InquiryProductType.KUKE_KOM_EP) && this.isNotType(InquiryProductType.KUKE_GSP_PBG);
  }

  isLongOffer() {
    return isShortOffer(this.getType()) === false;
  }

  isPremiumSectionVisible() {
    return this.isNotType(InquiryProductType.KUKE_GSP_PBG);
  }

  maxPaymentDateDisabled() {
    return this.isType(InquiryProductType.KUKE_KOM_EP);
  }

  contractDurationDisabled() {
    if (this.offerEnvelope.offer.permanentContract) {
      return true;
    }
    return (
      ((this.isType(InquiryProductType.KUKE_KOM_EP) || this.isType(InquiryProductType.KUKE_GSP_PBG)) &&
        !this.isAnnexOrRenewalOffer) ||
      this.isLongOffer()
    );
  }

  permanentContractDisabled() {
    return this.isType(InquiryProductType.KUKE_GSP_PBG) || this.cannotModifyPolicyDuration || !this.isEdge();
  }

  permanentContractVisible() {
    return this.isNotType(InquiryProductType.KUKE_KOM_EP);
  }

  policyValidToVisible() {
    return !this.offerEnvelope.offer.permanentContract || this.isAnnexOffer;
  }

  rateDisabled() {
    return this.isType(InquiryProductType.KUKE_KOM_EP);
  }

  topUpDisabled() {
    return this.rateDisabled() || this.isType(InquiryProductType.KUKE_GSP_PBG);
  }

  isFetchFromCalculator() {
    return this.isProductPacked() && this.isEdge() && (!(this.isAnnexOffer === true) || !this.isMigrationPolicy());
  }

  private isMigrationPolicy(): boolean {
    const originId = this.inquiryVersion?.basePolicyContractVersion?.origin?.id;
    return originId === PolicyContractVersionOrigin.KUKE_MIGRATION || originId === PolicyContractVersionOrigin.SI_KUKE;
  }

  payableInInstallmentsDisabled() {
    return this.isType(InquiryProductType.KUKE_KOM_EP);
  }

  numberOfInstalmentsDisabled() {
    return false;
  }

  premiumLabelKey() {
    if (this.isType(InquiryProductType.KUKE_KOM_EP) || this.isEdge()) {
      return 'offer.details.premiumSection.premium';
    }
    return 'offer.details.premiumSection.premiumMin';
  }

  onTopUpChanged(checked: boolean) {
    if (!checked) {
      this.offerEnvelope.offer.zeroPlusRate = null;
    }
  }

  validateFixedMinPremiums(formGroup: UntypedFormGroup): ValidationErrors | null {
    if (!this.offerEnvelope || !this.offerEnvelope.offer) {
      return null;
    }

    return validateFixedMinPremiums(formGroup);
  }

  declaredTurnoverKey() {
    if (this.isType(InquiryProductType.KUKE_KOM_SP)) {
      return 'offer.details.edgeOfferDetailsSection.declaredBalance';
    }
    return 'offer.details.edgeOfferDetailsSection.declaredTurnover';
  }

  onOfferRefresh(func: () => Observable<PolicyInquiryOfferEnvelopeDto>) {
    this.offerRefresh.emit(func);
  }

  showClausesTab() {
    const offer = this.offerEnvelope && this.offerEnvelope.offer;
    return offer && offer.id > 0 && areClausesAvailable(offer.type);
  }

  private setInquiryVersion(inq) {
    console.log('setInquiryVersion in offer', inq);
    this.inquiryVersion = inq;

    this.refreshDeclaredTurnoverObserver.inquiryLoadEmit();
  }

  private refreshDeclaredTurnoverFromCountries() {
    let filterPredicate: (c: PolicyInquiryCountryDto) => boolean = null;
    if (this.isType(InquiryProductType.KUKE_KOM_OP) || this.isType(InquiryProductType.KUKE_KOM_SP)) {
      filterPredicate = () => true;
    } else if (this.isType(InquiryProductType.KUKE_KOM_FK)) {
      // domestic
      filterPredicate = null;
    } else if (this.isType(InquiryProductType.KUKE_KOM_FE)) {
      // export
      filterPredicate = InquiryCountries.exportPredicate;
    }

    if (filterPredicate == null) {
      return;
    }

    this.offerEnvelope.offer.declaredTurnover = InquiryCountries.countriesSumPlannedTO(
      this.inquiryVersion,
      filterPredicate
    );
    console.log('refreshDeclaredTurnoverFromCountries ', this.offerEnvelope.offer.declaredTurnover);
  }

  private loadUsers() {
    if (this.isEdge() || Array.isArray(this.bokUsers) || this.bokUsers === false) {
      return;
    }
    this.bokUsers = false; // false means request pending
    this.userService.getIntranetUsers().subscribe((res) => {
      this.bokUsers = res;
    });
  }

  // task to finish this parti is preparing
  // private nnLimitClauseUpdate() {
  //   this.nnLimitClause = null;
  //
  //   const clauses = this.offerEnvelope && this.offerEnvelope.offer
  //                     && this.offerEnvelope.offer.clauses;
  //   if (!clauses || !clauses.length) {
  //     return;
  //   }
  //
  //   clauses.find(c => c.type);
  // }

  private isProductPacked() {
    const offer = this.offerEnvelope.offer;
    return isProductPacked(offer && offer.type);
  }

  isEdge() {
    const ofe = this.offerEnvelope && this.offerEnvelope.offer;
    return ofe && ofe.edge;
  }

  isKukeGspPbg() {
    return this.isType(InquiryProductType.KUKE_GSP_PBG);
  }

  isPbgClient() {
    return this.isKukeGspPbg() && !this.isEdge();
  }

  isPbg() {
    return this.isKukeGspPbg();
  }

  get isInitialOffer(): boolean {
    return isInitialOffer(this._offerEnvelope.offer);
  }

  get isAnnexOffer(): boolean {
    return isAnnexOffer(this._offerEnvelope.offer);
  }

  get cannotModifyPolicyDuration(): boolean {
    if (!this.isEditable()) {
      return true;
    }

    return this.isAnnexOffer && !this.getPolicyContract().last;
  }

  get shouldRecalculateValidTo(): boolean {
    if (this.policyValidFromPresentationMode()) {
      return false;
    } else {
      return (
        this.isInitialOffer ||
        (!this.isAnnexOffer || this.isEdge()
          ? this._offerEnvelope.offer.policyInquiryVersion.policyInquiry.policyContract.last
          : this._offerEnvelope.offer.parent.policyInquiryVersion.policyInquiry.policyContract.last)
      );
    }
  }

  get canModifyValidToManually(): boolean {
    if (this.isEditable() && this.isAnnexOffer) {
      return this.isEdge()
        ? this._offerEnvelope.offer.policyInquiryVersion.policyInquiry.policyContract.last &&
            !!this._offerEnvelope.offer.policyInquiryVersion.basePolicyContractVersion
        : this._offerEnvelope.offer.parent.policyInquiryVersion.policyInquiry.policyContract.last &&
            this._offerEnvelope.offer.parent.policyInquiryVersion.basePolicyContractVersion.lastActivated;
    } else {
      return false;
    }
  }

  get isRenewalOffer(): boolean {
    return isRenewalOffer(this._offerEnvelope.offer);
  }

  get isAnnexOrRenewalOffer(): boolean {
    return this.isAnnexOffer || this.isRenewalOffer;
  }

  isRequiredPbg() {
    return this.isKukeGspPbg();
  }

  isEditable(status?: PolicyInquiryOfferStatus) {
    if (!this.canSave) {
      return false;
    }

    const offerStatus: PolicyInquiryOfferStatus =
      this.offerEnvelope &&
      this.offerEnvelope.offer &&
      this.offerEnvelope.offer.businessStatus &&
      this.offerEnvelope.offer.businessStatus.id;

    if (!offerStatus) {
      return false;
    }
    if (isStandardEditable(offerStatus)) {
      return true;
    }
    return isNarrowEditable(offerStatus, this.getType()) && offerStatus === status;
  }

  policyValidFromPresentationMode(): boolean {
    return (
      ((this.isRenewalOffer || this.isPbgClient()) && !this.isAnnexOffer) ||
      !this.isEditable(PolicyInquiryOfferStatus.ACTIVE)
    );
  }

  private recalculateValidToDate(force: boolean = false) {
    if (!this.offerEnvelope.offer.policyValidFrom) {
      this.offerEnvelope.offer.policyValidTo = undefined;
    } else {
      if (force || this.isEditable()) {
        if (this.offerEnvelope.offer.insurancePeriod !== undefined) {
          this.offerEnvelope.offer.policyValidTo = moment(this.offerEnvelope.offer.policyValidFrom)
            .startOf('day')
            .add(this.offerEnvelope.offer.insurancePeriod, 'months')
            .subtract(1, 'day')
            .endOf('day')
            .toDate();
        } else {
          this.offerEnvelope.offer.policyValidTo = undefined;
        }
      }
    }
  }

  private recalculateContractDuration(force: boolean = false) {
    if (force || (this.isEditable() && ((this.isLongOffer() && this.isRenewalOffer) || this.isEdge()))) {
      const requiredParametersSet: boolean =
        this.offerEnvelope.offer.numberOfSettlementPeriods !== undefined &&
        this.offerEnvelope.offer.insurancePeriod !== undefined;
      if (requiredParametersSet) {
        this.offerEnvelope.offer.contractDuration =
          this.offerEnvelope.offer.numberOfSettlementPeriods * this.offerEnvelope.offer.insurancePeriod;
      }
    }
  }

  policyValidFromChanged() {
    if (this.shouldRecalculateValidTo && !this.canModifyValidToManually) {
      this.recalculateValidToDate(true);
    }
  }

  policyValidToChanged() {
    if (moment(this.offerEnvelope.offer.policyValidTo).isBefore(this.today)) {
      this.offerEnvelope.offer.policyValidTo = this.today;
    }
  }

  permanentContractChanged() {
    this.offerEnvelope.offer.numberOfSettlementPeriods = 1;
    this.recalculateContractDuration();
    if (this.shouldRecalculateValidTo && !this.canModifyValidToManually) {
      this.recalculateValidToDate();
    }
  }

  durationChanged() {
    this.recalculateContractDuration();
    this.recalculateValidToDate();
  }

  isClauseEditabe() {
    return this.isEditable() && this.offerEnvelope.offer.clauseCreationPossible;
  }

  isClauseExists() {
    return isClauseExists(this.offerEnvelope && this.offerEnvelope.offer);
  }

  noClausesVisible() {
    return this.isProductPacked();
  }

  onPermanentContractChange($event: Event) {
    if ((<HTMLInputElement>$event.target).checked) {
      this.offerEnvelope.offer.contractDuration = undefined;
    }
  }

  policyInsuranceRateRequired() {
    return this.isRequired && InquiryProductTypes.KUKE_PRODUCT_PACKED.includes(this.getInquiry().productType.id);
  }

  isIntegralFranchiseRequired() {
    return (
      this.isType(InquiryProductType.KUKE_KOM_OP) ||
      this.isType(InquiryProductType.KUKE_KOM_FK) ||
      this.isType(InquiryProductType.KUKE_KOM_FE) ||
      this.isType(InquiryProductType.KUKE_KOM_SP) ||
      this.isType(InquiryProductType.KUKE_KOM_EP)
    );
  }

  isInstallmentsNumberRequired() {
    return (
      this.isType(InquiryProductType.KUKE_KOM_OP) ||
      this.isType(InquiryProductType.KUKE_KOM_FK) ||
      this.isType(InquiryProductType.KUKE_KOM_FE) ||
      this.isType(InquiryProductType.KUKE_KOM_SP) ||
      this.isType(InquiryProductType.KUKE_KOM_EP)
    );
  }
}
