import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, NgForm, Validators} from '@angular/forms';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {
  BusinessUnitDto,
  DictionaryBaseDto,
  DictionaryDto,
  EmployeeDto,
  UserDto,
  UserHolidaySettingsDto,
} from '../../../bonding_shared/model/dtos';
import {
  DictionaryProperty, DictionaryPropertyType,
  ProfileCategory,
  UserRole,
  UserStatus,
  UserType
} from '../../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';
import {UserGroupBaseDto} from '../../../bonding_shared/model';
import {GrowlService} from '../../../bonding_shared/services/growl/growl.service';
import {DictionaryService} from '../../../bonding_shared/services/dictionary.service';
import {AppConfigService} from '../../../bonding_shared/services/app-config.service';
import {ContextsRoles} from '../../../bonding_shared/model/contexts-roles';
import {LoggedUserService, UserRange, UserService} from '../../../bonding_shared/services';
import {forkJoin as observableForkJoin} from 'rxjs';
import {UserUtils} from '../../../bonding_shared/utils/user-utils';
import {UserHolidaySettingsService} from '../../../bonding_shared/services/user-holiday-settings-service';

@Component({
  selector: 'user-data',
  templateUrl: 'user-data.component.html',
})
export class UserDataComponent extends DetailsView implements OnInit {
  readonly hrUserRange: UserRange = 'hr';
  readonly countryManagersUserRange: UserRange = 'countryManagers';

  readonly UserStatus = UserStatus;
  _user: UserDto = <UserDto>{roles: []};
  editionBlocked = false;
  userNotEditable = false;
  @Input() header: string;
  totalCount: number;
  errorMessage: string;
  showErrors = false;
  availableRolesForType: DictionaryBaseDto[] = [];
  confirmPassword = '';
  _groups: UserGroupBaseDto[];
  groupsForProfileCategory: UserGroupBaseDto[] = [];
  groupsForBu: UserGroupBaseDto[] = [];
  selectedProfileCategory: DictionaryBaseDto;
  profileCategories: DictionaryDto[] = [];
  private availableProfileCategories: number[] = [];
  emptyProfileCategoryIds: Set<number> = new Set();
  userAbsent = false;
  readonly contexts: ContextsRoles.CTX[];
  readonly contextsCombo;
  userRolesDictMap: Map<string, DictionaryDto>;

  extranetProfileCategories: DictionaryDto[] = [];
  langCodeRegexp: RegExp;
  public employeeCheckbox: boolean;
  public holidaysSettings: UserHolidaySettingsDto[];

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  @Input() set user(user: UserDto) {
    if (user) {
      this.userAbsent = !!(user.absentFrom && user.absentTo);
      this.confirmPassword = '';
      this._user = user;
      if (this.roles) {
        if (this.user.userType && this.user.userType.id === UserType.INTRANET) {
          this.reloadAvailableRoles();
          if (this.user.id > 0 && this.user.roles && this.user.roles.length > 0) {
            for (let i = 0; i < this.user.roles.length; i++) {
              this.removeFromCollectionById(this.user.roles[i].id, this.availableRolesForType);
            }
          }
        }
      }
      if (this.appService.kuke && this.user.userType.id === UserType.INTRANET) {
        this.editionBlocked = true;
        if (this.user.status.id === UserStatus.DELETED_IN_AD || this.user.status.id === UserStatus.DISABLED_IN_AD) {
          this.userNotEditable = true;
        }
      }
      this.resetContexts();
    }
  }

  @Input() set groups(groups: UserGroupBaseDto[]) {
    this._groups = groups;
    if (this.user.businessUnit) {
      this.setGroupsForBu();
      this.removeAssignedGroups();
    }
  }

  get user(): UserDto {
    return this._user;
  }

  _roles: DictionaryDto[] = [];
  categoryUnavailable: (profileCategory: DictionaryBaseDto) => boolean;

  @Input() set roles(roles: DictionaryDto[]) {
    if (roles) {
      this._roles = roles;
      if (this.user.userType) {
        this.reloadAvailableRoles();
        if (this.user.id > 0 && this.user.roles && this.user.roles.length > 0) {
          for (let i = 0; i < this.user.roles.length; i++) {
            this.removeFromCollectionById(this.user.roles[i].id, this.availableRolesForType);
          }
        }
      }
    }
  }

  get roles(): DictionaryDto[] {
    return this._roles;
  }

  constructor(
    translateService: TranslateService,
    protected growlService: GrowlService,
    private dictionaryService: DictionaryService,
    public appService: AppConfigService,
    private userService: UserService,
    private loggedUserService: LoggedUserService,
    private userHolidaySettingsService: UserHolidaySettingsService
  ) {
    super(growlService);
    this.langCodeRegexp = UserUtils.resolveLangRegexp(appService);
    this.categoryUnavailable = this.appService.kuke
      ? (c) => this.availableProfileCategories.indexOf(c.id) === -1
      : (c) => this.getAvailableRolesForProfileCategory(c, false).length < 1;
    this.contexts = ContextsRoles.contexts(translateService);
    this.contextsCombo = this.contexts.map((c) => {
      return {
        value: c.code,
        label: c.label,
      };
    });
  }

  reset() {
    this.showErrors = false;
  }

  cleanRetype() {
    this.confirmPassword = '';
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this.selectorNameList = ['Company', 'CreatorCompany'];
    this.initializeSelectorEmitters(true);
    if (!this.user.roles) {
      this.user.roles = [];
    }

    this.employeeCheckbox = !!this.user.employee;

    if (this.appService.kuke) {
      observableForkJoin([
        this.dictionaryService.getDictionaryEntry('ProfileCategory', ProfileCategory.KUKE_EXT_POLICIES),
        // hide Bonding module
        this.dictionaryService.getDictionaryEntry('ProfileCategory', ProfileCategory.KUKE_EXT_BONDING),
        this.dictionaryService.getDictionaryEntry('ProfileCategory', ProfileCategory.KUKE_EXT_ADMIN),
      ]).subscribe((res) => {
        this.extranetProfileCategories = res;
      });
      this.userService.getAvailableProfileCategories('USER_ROLE_UPDATE').subscribe((dd) => {
        this.availableProfileCategories = dd;
        this.loadProfileCategories();
      });
    } else if (this.appService.ecg) {
      observableForkJoin([
        this.dictionaryService.getDictionaryEntry('ProfileCategory', ProfileCategory.ECG_EXT_BROKER),
        // hide Bonding module
        this.dictionaryService.getDictionaryEntry('ProfileCategory', ProfileCategory.ECG_EXT_CLIENT),
      ]).subscribe((res) => {
        this.extranetProfileCategories = res;
      });
      this.loadProfileCategories();
    } else {
      this.loadProfileCategories();
    }

    this.dictionaryService.getDictionary('UserRole').subscribe((d) => {
      this.userRolesDictMap = new Map<string, DictionaryDto>(d.map((x) => [x.code, x] as [string, DictionaryDto]));
    });
    if (this.credendo) {
      this.getUserHolidaySettings(this.user.id);
    }
  }

  loadProfileCategories() {
    this.dictionaryService.getDictionary('ProfileCategory').subscribe((d) => {
      this.profileCategories = d;
      d.forEach(pc => {
        console.log('!!' + pc.name + ' prop ' + pc.properties);
        console.log('!!' + pc.properties[DictionaryPropertyType.MANY_ROLES_FROM_CATEGORY]);
      })
      this.buildEmptyProfileCategoryIdList();
      this.selectedProfileCategory = this.getFirstAvailableProfileCategory();
      this.reloadAvailableRoles();
      this.reloadAvailableGroups();
    });
  }

  getFirstAvailableProfileCategory() {
    const availableProfiles = this.profileCategories.filter((p) => !this.emptyProfileCategoryIds.has(p.id));
    if (availableProfiles.length > 0) {
      return availableProfiles[0];
    }
    return undefined;
  }

  onBUChange(bu: BusinessUnitDto) {
    this.form.controls['businessUnit'].setValue(bu);
    this.form.controls['businessUnit'].updateValueAndValidity();
    if (!this.appService.kuke) {
      this.setGroupsForBu();
      this.user.groups = [];
    }
  }

  onUserTypeChange() {
    this.clearChosenRoles();
    this.buildEmptyProfileCategoryIdList();
    this.selectedProfileCategory = this.getFirstAvailableProfileCategory();
    this.reloadAvailableRoles();
    this.reloadAvailableGroups();
    if (this.form.get('defaultContextCode')) {
      this.form.get('defaultContextCode').updateValueAndValidity();
    }
  }

  onEmployeeChange() {
    if (this.employeeCheckbox) {
      this.user.employee = <EmployeeDto>{};
    } else {
      this.user.employee = null;
    }
  }

  reloadAvailableRoles() {
    this.availableRolesForType = this.getAvailableRolesForProfileCategory(this.selectedProfileCategory, true);
  }

  reloadAvailableGroups() {
    this.groupsForProfileCategory = this.getAvailableGroupsForProfileCategory(this.selectedProfileCategory, true);
  }

  userTypeRoles(userType: DictionaryBaseDto) {
    if (userType) {
      for (const dict of this.roles) {
        if (dict.id === userType.id) {
          return dict.relatedDictionaries['UserRole'];
        }
      }
    }
    return [];
  }

  buildEmptyProfileCategoryIdList() {
    this.emptyProfileCategoryIds = new Set(this.profileCategories.filter(this.categoryUnavailable).map((c) => c.id));
  }

  getAvailableRolesForProfileCategory(
    profileCategory: DictionaryBaseDto,
    onlyUnassigned: boolean
  ): DictionaryBaseDto[] {
    return this.userTypeRoles(this.user.userType)
      .filter((r) => !profileCategory || r.parentId === profileCategory.id)
      .filter((r) => !onlyUnassigned || !this.user.roles || !this.user.roles.find((ur) => r.id === ur.id));
  }

  getAvailableGroupsForProfileCategory(
    profileCategory: DictionaryBaseDto,
    onlyUnassigned: boolean
  ): UserGroupBaseDto[] {
    return this.groupsForBu
      .filter((r) => !profileCategory || r.profileCategory.id === profileCategory.id)
      .filter((r) => !onlyUnassigned || !this.user.groups || !this.user.groups.find((ur) => r.id === ur.id));
  }

  onProfileCategoryChange() {
    this.reloadAvailableRoles();
    this.reloadAvailableGroups();
  }

  private setGroupsForBu() {
    this.groupsForBu = this._groups.sort((a, b) => (a.name > b.name ? 1 : -1));
    if (this.appService.kuke) {
      this.reloadAvailableGroups();
    } else {
      this.groupsForProfileCategory = this.groupsForBu;
    }
  }

  private removeAssignedGroups() {
    this.user.groups.forEach((g) => this.removeFromCollectionById(g.id, this.groupsForBu));
  }

  clearChosenRoles() {
    if (this.availableRolesForType && this.user.roles && this.user.roles.length > 0) {
      for (const role of this.user.roles) {
        this.availableRolesForType.push(role);
      }
      this.user.roles.length = 0;
    }
  }

  addRole(role: DictionaryBaseDto) {
    if (this.userNotEditable) {
      return;
    }
    if (role.id === UserRole.KUKE_SUPERADMIN) {
      return;
    }
    this.removeFromCollectionById(role.id, this.availableRolesForType);

    const pc = this.profileCategories.find(pc => pc.id === role.parentId);
    if (!pc.properties || pc.properties[DictionaryPropertyType.MANY_ROLES_FROM_CATEGORY] !== 'true') {
      const categoryRoles = this.user.roles.filter((r) => r.parentId === role.parentId);
      if (categoryRoles.length > 0) {
        this.removeFromCollectionById(categoryRoles[0].id, this.user.roles);
        this.availableRolesForType.push(categoryRoles[0]);
      }
    }
    this.user.roles.push(role);
  }

  addGroup(role: UserGroupBaseDto) {
    if (this.userNotEditable) {
      return;
    }
    this.removeFromCollectionById(role.id, this.groupsForProfileCategory);
    this.removeFromCollectionById(role.id, this.groupsForBu);
    this.user.groups.push(role);
  }

  removeRole(role: DictionaryBaseDto) {
    if (this.userNotEditable) {
      return;
    }
    if (!this.userProfilesEditable() || role.id === UserRole.KUKE_SUPERADMIN) {
      return;
    }
    if (this.appService.kuke && role.parentId !== this.selectedProfileCategory.id) {
      return;
    }
    this.removeFromCollectionById(role.id, this.user.roles);
    if (!this.selectedProfileCategory || role.parentId === this.selectedProfileCategory.id) {
      this.availableRolesForType.push(role);
    }
  }

  removeGroup(group: UserGroupBaseDto) {
    if (this.userNotEditable) {
      return;
    }
    if (!this.userGroupsEditable()) {
      return;
    }
    if (this.appService.kuke && group.profileCategory.id !== this.selectedProfileCategory.id) {
      return;
    }
    this.removeFromCollectionById(group.id, this.user.groups);
    this.groupsForProfileCategory.push(group);
    this.groupsForBu.push(group);
  }

  removeFromCollectionById(id: number, list: any[]) {
    for (let i = 0; i < list.length; i++) {
      if (id === list[i].id) {
        list.splice(i, 1);
        break;
      }
    }
  }

  userTypeConstant(): boolean {
    return this.isUserSaved() || this.appService.kuke;
  }

  userProfilesEditable(): boolean {
    return !this.isUserSaved() || this.isIntranetUser();
  }

  userGroupsEditable(): boolean {
    return !this.isUserSaved() || this.isIntranetUser();
  }

  private isUserSaved(): boolean {
    const u = this._user;
    return !!(u && u.id);
  }

  public isIntranetUser(): boolean {
    const u = this._user;
    return u && u.userType && u.userType.id === UserType.INTRANET;
  }

  public isCredendoEmployee(): boolean {
    return this.credendo && this.employeeCheckbox;
  }

  kukeExternal() {
    return !this.isIntranetUser() && this.appService.kuke;
  }

  ecgExternal() {
    return !this.isIntranetUser() && this.appService.ecg;
  }

  showProfiles() {
    return (!this.isIntranetUser() && this.appService.credendo) || this.isIntranetUser();
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  get mehib(): boolean {
    return this.appService.mehib;
  }

  showPassword() {
    return (
      this.appService.mehib ||
      this.appService.credendo ||
      (this.appService.ecg && this.isIntranetUser()) ||
      (this.appService.kuke && this.isIntranetUser())
    );
  }

  private resetContexts() {
    if (!this.kukeExternal()) {
      return;
    }

    ContextsRoles.updateAvailability(this.contexts, this.loggedUserService);
  }

  onContextChange(r: DictionaryBaseDto) {
    if (this.hasRole(r)) {
      if (r.id === UserRole.KUKE_PORTAL_ADMIN) {
        this._user.roles = [];
      } else {
        this._user.roles = this._user.roles.filter((d) => d.id !== r.id);
      }
    } else {
      if (r.id === UserRole.KUKE_PORTAL_ADMIN) {
        this._user.roles = [];
        this.extranetProfileCategories.forEach((pc) => this._user.roles.push(...pc.relatedDictionaries['UserRole']));
      } else {
        this._user.roles.push(r);
      }
    }
  }

  private defaultContextCodeValidator(control: AbstractControl) {
    if (!this.kukeExternal()) {
      return null;
    }
    return Validators.required(control);
  }

  onAbsenceChange(absence: boolean) {
    if (!absence) {
      this._user.absentFrom = undefined;
      this._user.absentTo = undefined;
    }
  }

  hasRole(r: DictionaryBaseDto): boolean {
    return this._user.roles && this._user.roles.find((d) => d.id === r.id) !== undefined;
  }

  hasRoleById(id: number): boolean {
    return this._user.roles && this._user.roles.find((d) => d.id === id) !== undefined;
  }

  hasPortalAdminChkecked(r: DictionaryBaseDto): boolean {
    // Skip for portal admin checkbox so it is enable
    if (r.id === UserRole.KUKE_PORTAL_ADMIN) {
      return false;
    }
    return this.hasRoleById(UserRole.KUKE_PORTAL_ADMIN);
  }

  private getUserHolidaySettings(userId: number) {
    this.holidaysSettings = null;
    this.userHolidaySettingsService
      .getUserHolidaySettings(userId)
      .subscribe((res) => (this.holidaysSettings = res.result));
  }
}
