import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {ElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {
  ATableComponent,
  ErrorReason,
  GrowlService,
  isErrorReasons,
  LimitBaseDto,
  LimitRequestCriteriaDto,
  LimitRequestSimpleDto,
  LimitService,
  LoggedUserService,
  PolicyInquiryVersionDto,
  RouterService,
  SearchDataProvider,
  SortBy,
} from '../../../bonding_shared';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {InquiryAbstractService} from '../../../bonding_shared/services/inquiry-abstract.service';
import {isRegistrationCompletedAvailable} from '../domain/offer-allowance';

@Component({
  selector: 'inquiry-limits',
  templateUrl: 'inquiry-limits.component.pug',
})
export class InquiryLimitsComponent implements AfterViewInit {
  self = this;

  @Input()
  inquiry: PolicyInquiryVersionDto;

  @ViewChild('limitsTableInOffer') limits: ATableComponent<LimitRequestSimpleDto>;

  show: boolean;
  portalMode = false;
  dataProvider: SearchDataProvider<LimitRequestCriteriaDto, LimitRequestSimpleDto>;

  constructor(
    limitService: LimitService,
    private loggedUserService: LoggedUserService,
    public router: RouterService,
    private inquiryService: InquiryAbstractService,
    private growlService: GrowlService
  ) {
    this.dataProvider = new SearchDataProvider<LimitRequestCriteriaDto, LimitRequestSimpleDto>(limitService);
    const sc = this.dataProvider.searchCriteria;
    sc.criteria = <LimitRequestCriteriaDto>{};
    // @ts-ignore
    // sc.criteria.status = {id: LimitRequestStatus.DONE};
    sc.criteria.last = true;
    // @ts-ignore
    // sc.criteria.decisionStatus = {id: LimitDecisionStatus.APPROVED};
    sc.sortBy = <SortBy>{direction: 'UP', column: 'id'};
    this.dataProvider.textSearch = false;
    this.show = loggedUserService.hasRight(ElementaryRight.ACCESS_MODULE_LIMITS);
    this.portalMode = this.loggedUserService.portal;
  }

  ngAfterViewInit() {
    setTimeout(() => this.limitSearch(), 500);
  }

  private limitSearch() {
    const sc = this.dataProvider.searchCriteria;
    sc.criteria.policyInquiryIds = [this.inquiry.policyInquiry.id];
    this.limits.search(5);
  }

  getBuyerName(limit: LimitBaseDto) {
    return BusinessUtils.companyName(this.getBuyer(limit).company);
  }

  getBuyerCountry(limit: LimitBaseDto): string {
    const buyer = this.getBuyer(limit);
    return buyer && buyer.company && buyer.company.address.country && buyer.company.address.country.code;
  }

  getBuyer(limit: LimitBaseDto) {
    return BusinessUtils.getThirdPartyCompany(limit.buyer);
  }

  getLp(item) {
    if (this.limits) {
      return this.limits.getItemIndex(item) + 1;
    }
    return null;
  }

  registrationCompleted() {
    this.inquiryService.registrationCompleted(this.inquiry.id).subscribe({
      next: (_) => {
        this.inquiry.limitsRegistrationCompleted = true;
        this.growlService.notice('Success!');
      },
      error: (error) => {
        let msg = 'Error!';
        if (isErrorReasons(error) && (<ErrorReason[]>error).length > 0) {
          msg = (<ErrorReason[]>error)[0].message;
        }
        this.growlService.error(msg, 'Error!');
      },
    });
  }

  isRegistrationCompletedAvailable() {
    return !!(
      this.limits &&
      this.limits.totalCount > 0 &&
      this.loggedUserService.hasRight(ElementaryRight.PORTAL_INQUIRY_REGISTRATION_COMPLETED) &&
      isRegistrationCompletedAvailable(this.portalMode, this.inquiry)
    );
  }
}
