import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm, Validators} from '@angular/forms';
import {
  BusinessPropertiesService,
  BusinessUnitIdDto,
  CustomValidators,
  DictionaryBaseService,
  DictionaryDto,
  LoggedUserService,
  PolicyContractService,
  PolicyContractVersionDto,
  RouterService,
  UserIdDto,
  UserRange,
  UserService,
} from '../../../bonding_shared';
import {AbstractInquiryProduct} from './abstract-inquiry.product';
import {VisibilityService} from '../services/visibility.service';
import {UserRole} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'inquiry-product-ecg-top-up',
  templateUrl: './inquiry-product-ecg-top-up.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class InquiryProductEcgTopUpComponent extends AbstractInquiryProduct implements OnInit {
  @Input() myOptions: DictionaryDto[];

  readonly representativeOfficeUserRange: UserRange = 'representativeOffice';

  underwriters: UserIdDto[] = null;
  isClientUser = false;

  constructor(
    dictionaryBaseService: DictionaryBaseService,
    router: RouterService,
    businessPropertiesService: BusinessPropertiesService,
    private userService: UserService,
    protected policyContractService: PolicyContractService,
    protected loggedUserService: LoggedUserService,
    protected visibilityService: VisibilityService
  ) {
    super(
      dictionaryBaseService,
      router,
      loggedUserService,
      policyContractService,
      businessPropertiesService,
      visibilityService
    );

    this.isClientUser = loggedUserService
      .getLoggedUserData()
      .roles.some((role) => [UserRole.ECG_CLIENT_POLICIES, UserRole.ECG_CLIENT_ADMIN].includes(role.id));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadUnderwriters();
    this.initForm();
    this.formUpdate();
  }

  private initForm() {
    this.form.get('domesticInsurable').addValidators(Validators.required);
    this.form.get('exportInsurable').addValidators(Validators.required);
    this.form
      .get('turnoverInsurable')
      .setValidators(
        Validators.compose([
          Validators.required,
          CustomValidators.eqSumOfFields(['domesticInsurable', 'exportInsurable']),
        ])
      );
  }

  protected loadSalesUsers() {
    const businessUnit: BusinessUnitIdDto = this.inquiry.businessUnit;
    this.userService.getSalesUsers(businessUnit).subscribe((users) => {
      this.salesUsers = users;
    });
  }

  protected loadUnderwriters() {
    if (!this.readonly) {
      this.userService.getPolicySupport().subscribe((res) => {
        this.underwriters = res;
      });
    }
  }

  onSelectPolicy(c: PolicyContractVersionDto) {
    this.inquiry.policyInquiry.policyContract = c.policyContract;
  }

  protected formUpdate() {
    if (this._inquiry && this.form) {
      this.form.get('turnoverInsurable').setValue(this._inquiry.turnoverInsurable);
      this.form.get('domesticInsurable').setValue(this._inquiry.domesticInsurable);
      this.form.get('exportInsurable').setValue(this._inquiry.exportInsurable);
    }
  }

  get isPortal(): boolean {
    return this.loggedUserService.portal;
  }
}
